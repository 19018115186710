export enum ExperimentIds {
    // do not remove this is permanent exp for splitting users into segments
    SPLIT_INTO_TWO_SEGMENTS = "webtest_split_into_two_segments",
    FEMALE_PILATES_WEB_FUNNEL = "webtest_fempilates_feb24",
    UPSELL_NUTRITION_BOOK = "webtest_upsell_bundle_scannutr",
    NEW_PAYMENT_VERSION = "webtest-palta-new-sdk-2",
    WTP_RESEARCH = "webtest_WTPresearch_jun24",
    DISABLE_PREVENT_DUPLICATE_FLOW = "disable_prevent_duplicate_flow",
    MALE_CALISTHENICS = "webtest_calisthenics_FAEX_495_jul24",
    COHORT_WITHOUT_INTRO_OFFER = "webtest_cohort_without_intro_offer_FAEX_500_jul24",
    BACKUP_SUB_PLUS_BC = "webtest_backup_sub_plus_BC_juj24",
    UX_PAYWALL_ABC_ITER1 = "webtest_UXpaywall_abc_iter1_FAEX_490_jul24",
    WEIGHT_PREDICTOR_V3 = "webtest_weight_pred_iter3_FAEX_487_jul24",
    USER_SUMMARY = "webtest_user_summary_FAEX-496_jul24",
    UX_PAYWALL_ABC_ITER2 = "webtest_UXpaywall_abc_iter2_FAEX_514_jul24",
    COMPARE_USERS_RESPONSES = "webtest_compare_response_FAEX_501_jul24",
    LONG_PAYWALL_DISCLAIMER = "webtest_long_paywall_disclaimer_FAEX-523",
    IOS_WARNING_FIRST_SCREEN = "webtest_ios_warning_first_screen_GW_453_aug24",
    REWORK_UPSELL_NUTRITION = "webtest_rework_upsell_nutrition_FAEX_508_aug24",
    ASK_NAME = "webtest_ask_name_FAEX_510_aug24",
    WEEK_TRIAL = "webtest_week_trial_FAEX_525_aug24",
    NUMBER_OF_STEPS = "webtest_number_of_step_FAEX_509_aug24",
    FAKE_BUY_PAYWALL = "webtest_ios_fake_paywall_buy_GW_532_aug24",
}

export const enum ExperimentVariants {
    CONTROL = "control",
    TEST = "test",
    TEST_1 = "test_1",
    TEST_2 = "test_2",
    TEST_3 = "test_3",
    NOT_ALLOCATED = "not_allocated",
    SEGMENT_A = "segment_A",
    SEGMENT_B = "segment_B",
}

export const TEST_VARIANTS = [
    ExperimentVariants.TEST,
    ExperimentVariants.TEST_1,
    ExperimentVariants.TEST_2,
    ExperimentVariants.TEST_3,
];
