import { extendTailwindMerge } from "tailwind-merge";

const customTwMerge = extendTailwindMerge({
    cacheSize: 0, // ← Disabling cache
    // ↓ Here you define class groups
    classGroups: {
        // ↓ The `foo` key here is the class group ID
        //   ↓ Creates group of classes which have conflicting styles
        //     Classes here: foo, foo-2, bar-baz, bar-baz-1, bar-baz-2
        foo: ["foo", "foo-2", { "bar-baz": ["", "1", "2"] }],
        //   ↓ Functions can also be used to match classes.
        //     Classes here: qux-auto, qux-1000, qux-1001, …
        bar: [{ qux: ["auto", (value: number | string) => Number(value) >= 1000] }],
        fontSize: [
            {
                text: [
                    "xxxs",
                    "xxs",
                    "sm",
                    "md",
                    "ml",
                    "lg",
                    "xl",
                    "2xl",
                    "3xl",
                    "zing-9-14",
                    "zing-12-18",
                    "zing-16-20",
                    "zing-16-22",
                    "zing-18-26",
                    "zing-24-32",
                    "zing-32-40",
                    "zing-40-48",
                    "zing-60-76",
                    "zing-80-96",
                ],
            },
        ],
    },
    // ↓ Here you can define additional conflicts across different groups
    conflictingClassGroups: {
        // ↓ ID of class group which creates a conflict with …
        //     ↓ … classes from groups with these IDs
        foo: ["bar"],
    },
});

export default customTwMerge;
