import { matchPath } from "react-router-dom";
import { Routes as AppRoutes } from "@/constants/routes";
import { Genders } from "@/types/gender";

const enum BothFunnelTypes {
    both = "both",
    friends = "friends",
    offer = "offer",
}

type FunnelTypes = BothFunnelTypes | Genders;

export const getFunnelTypeForAnalytics = (gender: Genders): FunnelTypes => {
    const isBoth = matchPath(
        {
            path: `/${AppRoutes.Both}`,
            end: false,
        },
        window.location.pathname
    );
    const isFriends = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Friends}`,
            end: false,
        },
        window.location.pathname
    );
    const isOfferUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
            end: false,
        },
        window.location.pathname
    );

    if (isBoth) {
        if (isFriends) return BothFunnelTypes.friends;
        if (isOfferUrl) return BothFunnelTypes.offer;
        return BothFunnelTypes.both;
    }

    return gender;
};
