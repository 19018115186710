import { ReactComponent as LogoSVG } from "@/assets/img/logo/logo-white.svg";
import { Colors } from "@/constants/colors";
import { useTheme } from "@/utils/ThemeProvider";
import { FC } from "react";
import classNames from "classnames";
import customTwMerge from "@/utils/customTwMerge";

interface LogoProps {
    className?: string;
    fill?: Colors;
}
const Logo: FC<LogoProps> = ({ className, fill }) => {
    const { isLightTheme } = useTheme();
    return (
        <LogoSVG
            fill={fill ?? (isLightTheme ? Colors.BLACK : Colors.WHITE)}
            className={customTwMerge(classNames("box-content h-8", className))}
        />
    );
};

export default Logo;
