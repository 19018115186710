import imgCut from "@/male/assets/paywall-body/lean.webp";
import imgBulk from "@/male/assets/paywall-body/muscular.webp";
import imgExtraBulk from "@/male/assets/paywall-body/ripped.webp";
import imgSkinny from "@/male/assets/paywall-body/Ectomorph.png";
import imgRegular from "@/male/assets/paywall-body/Mesomorph.png";
import imgExtra from "@/male/assets/paywall-body/Endomorph.png";
import extra from "@/male/assets/paywall-body/extra.png";
import muscular from "@/male/assets/body-type/muscular.webp";
import ripped from "@/male/assets/body-type/ripped.webp";
import lean from "@/male/assets/body-type/lean.webp";

interface ManBodyPicByBodyId {
    skinny: string;
    regular: string;
    extra: string;
    cut: string;
    bulk: string;
    extra_bulk: string;
    lean: string;
    muscular: string;
    ripped: string;
    slightly_extra: string;
}
export const manBodyPicByBodyId: ManBodyPicByBodyId = {
    skinny: imgSkinny,
    regular: imgRegular,
    extra: extra,
    cut: imgCut,
    bulk: imgBulk,
    extra_bulk: imgExtraBulk,
    lean: lean,
    muscular: muscular,
    ripped: ripped,
    slightly_extra: imgExtra,
};
