import {
    captureMessage as captureSentryMessage,
    captureException as captureSentryException,
    init,
    reactRouterV6BrowserTracingIntegration,
    replayIntegration,
    thirdPartyErrorFilterIntegration,
} from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import { SENTRY_APPLICATION_KEY } from "@/constants/env";
import { Tracker } from "./common";

class Sentry extends Tracker {
    constructor() {
        super();
    }

    init() {
        if (!this._isInitialized) {
            if (process.env.REACT_APP_SENTRY_DSN) {
                init({
                    dsn: process.env.REACT_APP_SENTRY_DSN,
                    integrations: [
                        reactRouterV6BrowserTracingIntegration({
                            useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes,
                        }),
                        replayIntegration(),
                        thirdPartyErrorFilterIntegration({
                            filterKeys: [SENTRY_APPLICATION_KEY],
                            behaviour: "drop-error-if-contains-third-party-frames",
                        }),
                    ],
                    ignoreErrors: [
                        "Cannot read properties of undefined (reading 'domInteractive')",
                        "undefined is not an object (evaluating 'this._perf.domInteractive')",
                        "Importing a module script failed.",
                        /Failed to fetch dynamically imported module:.*/,
                        /Unable to preload CSS .*/,
                        "Non-Error promise rejection captured with value: JSBRIDGE TIMEOUT",
                        "Load failed",
                    ],
                    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "development",
                    release: process.env.REACT_APP_SENTRY_RELEASE || undefined,
                    // This should be a float/double between 0.0 and 1.0 (inclusive) and represents the percentage chance that any given transaction will be sent to Sentry.
                    // more https://develop.sentry.dev/sdk/performance/#tracessamplerate
                    tracesSampleRate: 1.0,

                    // Capture Replay for 10% of all sessions,
                    // plus for 100% of sessions with an error
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                });

                this._isInitialized = true;
            }
        }
    }

    captureMessage(message: string) {
        this._isInitialized && !this._isStoped && captureSentryMessage(message, "log");
    }

    captureException(error: unknown, hint?: Record<string, unknown>) {
        this._isInitialized && !this._isStoped && captureSentryException(error, hint);
    }
}

export default Sentry;
