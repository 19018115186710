export const enum LocalStorageManagerKeys {
    EPPO_UUID = "EPPO_UUID",
    EMAIL = "ZING_WEB_EMAIL",
    USER_UUID = "ZING_WEB_USER_UUID",
    IS_AUTH_STEP = "IS_AUTH_STEP",
    IS_SKIP_BODY_SCAN = "IS_SKIP_BODY_SCAN",
    IS_SKIP_NUTRITION_BOOK = "IS_SKIP_NUTRITION_BOOK",
    IS_AUTH_PASSED = "IS_AUTH_PASSED",
    IS_PAYWALL_STEP = "IS_PAYWALL_STEP",
    ANSWERS = "ZING_ANSWERS",
    EXTERNAL_ID = "AD_SIDE_CUSTOMER_ID",
    BOUGHT_PLAN_IDENT = "ZING_BOUGHT_PLAN_IDENT",
    EPPO_LOCAL_STORAGE_ASSIGNMENT_CACHE = "EPPO_LOCAL_STORAGE_ASSIGNMENT_CACHE",
    IS_PURCHASED_WITH_BODY_SCAN = "IS_PURCHASED_WITH_BODY_SCAN",
}

const storeItem = (key: LocalStorageManagerKeys, value: unknown): void => {
    if (value === undefined) return;
    if (value) {
        try {
            const data = JSON.stringify(value);
            localStorage.setItem(key, data);
        } catch (e) {
            console.error(e);
        }
    } else {
        localStorage.removeItem(key);
    }
};

const getStoredItem = <T>(key: LocalStorageManagerKeys): T | null => {
    const data = localStorage.getItem(key);
    if (data) {
        try {
            const result = JSON.parse(data) as T;

            return result;
        } catch (e) {
            console.error(e);
        }
    }
    return null;
};

export const localStorageManager = {
    storeItem,
    getStoredItem,
};
