import React, {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import {
    CountryCodes,
    CurrencyCodeByCountryCode,
    CurrencyCodes,
    CurrencySymbolsByCurrencyCode,
} from "@/constants/currency";
import { countryCodeParam } from "./settings";
import { GeolocationAction, GeolocationActions, GeolocationResponse } from "@/types/geolocation";
import geolocationReducer from "@/utils/geolocationReducer";
import { setAnalyticsUserProperties } from "@/constants/amplitude";
import { useMatch } from "react-router-dom";
import { Routes as AppRoutes } from "@/constants/routes";

const GEOLOCATION_API =
    "https://ipgeolocation.abstractapi.com/v1/?api_key=210aca93a46b470eb3ca778311135268";

const DEFAULT_COUNTRY = (countryCodeParam as CountryCodes) || CountryCodes.US;
const DEFAULT_CURRENCY = CurrencyCodeByCountryCode[DEFAULT_COUNTRY] || CurrencyCodes.USD;

// set USD for all users (change in future)
const IS_NEW_PAYWALL = true;

export const CurrencyContext = createContext({
    country: DEFAULT_COUNTRY,
    currency: DEFAULT_CURRENCY,
    currencySymbol: CurrencySymbolsByCurrencyCode[DEFAULT_CURRENCY],
    isLoaded: false,
    loadCurrencies: async () => {
        return;
    },
    ipAddress: "",
    city: "",
    postalCode: "",
    region: "",
});

const GeolocationProvider = ({ children }: PropsWithChildren) => {
    const [geolocation, dispatchGeolocation] = useReducer(geolocationReducer, {
        country: DEFAULT_COUNTRY,
        city: "",
        postalCode: "",
        currency: DEFAULT_CURRENCY,
        ipAddress: "",
        region: "",
    });

    const [isLoaded, setSetLoaded] = useState(!!countryCodeParam);
    const isLoadingRef = useRef(false);

    const isOfferUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
        end: false,
    });

    const currencySymbol = useMemo(
        () =>
            geolocation?.currency
                ? CurrencySymbolsByCurrencyCode[geolocation.currency]
                : CurrencySymbolsByCurrencyCode[DEFAULT_CURRENCY],
        [geolocation.currency]
    );

    const getCurrencyFromGeoData = useCallback(
        ({ geoData, isOfferFlow }: { geoData: GeolocationResponse; isOfferFlow: boolean }) => {
            if (isOfferFlow) {
                return CurrencyCodes.USD;
            } else if (geoData.currency.currency_code === CurrencyCodes.EUR) {
                return CurrencyCodes.EUR;
            } else return CurrencyCodeByCountryCode[geoData.country_code] || DEFAULT_CURRENCY;
        },
        []
    );

    const loadCurrencies = useCallback(async () => {
        if (!countryCodeParam && !isLoaded && !isLoadingRef.current) {
            try {
                isLoadingRef.current = true;
                const response = await fetch(GEOLOCATION_API);
                const data: GeolocationResponse = await response.json();
                const geoData: GeolocationAction = {
                    type: GeolocationActions.SET_DATA,
                    currency: getCurrencyFromGeoData({ geoData: data, isOfferFlow: !!isOfferUrl }),
                    country: data.country_code,
                    ipAddress: data.ip_address,
                    postalCode: data.postal_code,
                    city: data.city,
                    region: data.region,
                };
                dispatchGeolocation(geoData);
                setAnalyticsUserProperties({
                    web_currency: geoData.currency,
                });
            } catch (e) {
                console.error(e);
            } finally {
                setSetLoaded(true);
                isLoadingRef.current = false;
            }
        }
    }, [isLoaded, getCurrencyFromGeoData, isOfferUrl]);

    return (
        <CurrencyContext.Provider
            value={{
                country: geolocation.country ?? DEFAULT_COUNTRY,
                currency:
                    isOfferUrl || IS_NEW_PAYWALL
                        ? CurrencyCodes.USD
                        : geolocation.currency ?? DEFAULT_CURRENCY,
                currencySymbol:
                    IS_NEW_PAYWALL || isOfferUrl
                        ? CurrencySymbolsByCurrencyCode[CurrencyCodes.USD]
                        : currencySymbol,
                isLoaded,
                loadCurrencies,
                ipAddress: geolocation.ipAddress ?? "",
                city: geolocation.city ?? "",
                postalCode: geolocation.postalCode ?? "",
                region: geolocation.region ?? "",
            }}
        >
            {children}
        </CurrencyContext.Provider>
    );
};

export const useCurrency = () => {
    const { currency, currencySymbol, isLoaded, loadCurrencies } = useContext(CurrencyContext);
    return { currency, currencySymbol, isLoaded, loadCurrencies };
};

export const useGeolocation = () => {
    const { ipAddress, country, city, postalCode, region } = useContext(CurrencyContext);
    return { ipAddress, country, city, postalCode, region };
};

export default memo(GeolocationProvider);
