import {
    getLanguage,
    Languages,
    languagesStrings,
    mapLocalizedLanguage,
} from "@/constants/languages";
import { useCallback, useEffect, useMemo } from "react";
import { setAnalyticsUserProperties } from "@/constants/amplitude";
import { useGender } from "@/utils/GenderProvider";
import i18next from "@/localization/i18next";

export const useLocalization = () => {
    const { isFemale } = useGender();

    const targetLanguage = useMemo(() => getLanguage(i18next.language), []);

    const getIsLang = useCallback(
        (lng: Languages) => !!targetLanguage && targetLanguage.includes(lng),
        [targetLanguage]
    );

    const isSpanish = useMemo(() => getIsLang(Languages.Spanish), [getIsLang]);
    const isEnglish = useMemo(() => getIsLang(Languages.English), [getIsLang]);
    const isGerman = useMemo(() => getIsLang(Languages.German), [getIsLang]);

    useEffect(() => {
        if (targetLanguage) {
            setAnalyticsUserProperties({
                web_funnel_language: languagesStrings[targetLanguage],
            });
        }
    }, [isFemale, targetLanguage]);

    return {
        isSpanish,
        isEnglish,
        isGerman,
        language: targetLanguage,
        localizedLanguage: mapLocalizedLanguage[targetLanguage],
    };
};
