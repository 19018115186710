import { COMPONENTS } from "@/constants/components";
import pulseLogo from "@/assets/icons/zing-pulse.svg";
import mailSvg from "@/assets/icons/mail.svg";
import lockSvg from "@/assets/icons/lock.svg";
import femaleUsers from "@/female/assets/loaders/female-loader-users.webp";
import testUsers from "@/male/assets/loaders/testUsers.webp";
import femaleLoader from "@/female/assets/loaders/female-loader.webp";
import maleLoader from "@/male/assets/loaders/male-loader.webp";
import femaleUser1 from "@/female/assets/reviews/female-user1.png";
import user1 from "@/male/assets/reviews/1.png";
import stars from "@/assets/img/reviewStars.svg";
import beforeAfterBg from "@/assets/img/paywall/before-after-bg.png";
import {
    FemPaywallImagesByBodyFatRange,
    PaywallImagesByBodyFatRange,
} from "@/constants/bodyMetrics";
import { manBodyPicByBodyId } from "@/constants/bodyPicByBodyId";
import { femaleBodyPicByBodyId } from "@/constants/bodyType";
import maleLoaderBackground from "@/male/assets/backgrounds/loader-background.webp";
import femaleLoaderBackground from "@/female/assets/backgrounds/loader-bg.webp";

export const imagesToPreload = (isFemale: boolean): Partial<Record<COMPONENTS, string[]>> => ({
    [COMPONENTS.FIRST_LOADER]: isFemale ? [femaleLoaderBackground] : [maleLoaderBackground],
    [COMPONENTS.LOADER]: isFemale ? [femaleUsers, femaleLoader] : [testUsers, maleLoader],
    [COMPONENTS.EMAIL]: isFemale ? [pulseLogo, mailSvg, lockSvg] : [mailSvg, lockSvg],
    [COMPONENTS.PROGRESS_LOADER]: isFemale ? [femaleUser1, stars] : [user1, stars],
    [COMPONENTS.AI_PAYWALL]: isFemale
        ? [
              beforeAfterBg,
              ...Object.values(FemPaywallImagesByBodyFatRange),
              ...Object.values(femaleBodyPicByBodyId),
          ]
        : [
              beforeAfterBg,
              ...Object.values(PaywallImagesByBodyFatRange),
              ...Object.values(manBodyPicByBodyId),
          ],
});
