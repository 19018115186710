import { memo, useEffect, useRef } from "react";
import { Route, Routes, useMatch } from "react-router-dom";
import "./App.css";
import { useBlockHorizontalSwipe } from "./hooks/useSwipe";
import { APP_CONTAINER_ID } from "./constants/elementsIds";
import { useLocalization } from "@/hooks/useLocalization";
import { lazyWithPreload } from "@/utils/lazyPreload";
import { Routes as AppRoutes } from "@/constants/routes";
import useConsent from "@/hooks/useConsent";
import { FlowTypes } from "@/utils/providers/types";
import { useEnableExperiment, useExperiment } from "@/utils/ExperimentsProvider";
import { ExperimentIds } from "@/constants/experiments";
import { DeviceTypes, useDeviceType } from "@/hooks/useDeviceType";
import TechDifficultiesScreen from "@/common/TechDifficultiesScreen";

const Router = lazyWithPreload(() => import("@/components/Router"));
const LoaderPromo = lazyWithPreload(() => import("@/components/Promo/LoaderPromo"));
const MainFlow = lazyWithPreload(() => import("@/MainFlow"));

const App = () => {
    const appContainerRef = useRef<HTMLDivElement | null>(null);
    const { deviceOS } = useDeviceType();

    useBlockHorizontalSwipe();
    useLocalization();
    useConsent();

    const isFriends = useMatch({
        path: `/:both/${AppRoutes.Friends}`,
        end: false,
    });

    const isPromoPaywall = useMatch({
        path: `/${AppRoutes.PromoPaywall}`,
        end: false,
    });

    const changeContainerHeight = () => {
        let height = window.innerHeight;

        if (window.visualViewport) {
            height = window.visualViewport.height;
        }

        const appContainerRef = document.getElementById(APP_CONTAINER_ID);
        if (appContainerRef) {
            appContainerRef.style.cssText = `--zing-window-height: ${height}px`;
        }
    };

    const { enableExperiment } = useEnableExperiment();

    const { isTest: warningFirstScreenTest, variant: warningFirstSreenVariant } = useExperiment({
        experimentId: ExperimentIds.IOS_WARNING_FIRST_SCREEN,
    });

    const isWarningFirstScreen = warningFirstSreenVariant !== undefined && warningFirstScreenTest;
    const isEnableWarningFirstScreenTest =
        (!!isFriends || !!isPromoPaywall) &&
        [DeviceTypes.IOS, DeviceTypes.DESKTOP].includes(deviceOS);

    useEffect(() => {
        enableExperiment({
            experimentId: ExperimentIds.IOS_WARNING_FIRST_SCREEN,
            enableCondition: isEnableWarningFirstScreenTest,
        });
        // eslint-disable-next-line
    }, [deviceOS]);

    useEffect(() => {
        changeContainerHeight();
        // TODO: implement debounce here
        window.addEventListener("resize", changeContainerHeight);
        window.visualViewport?.addEventListener("resize", changeContainerHeight);

        return () => {
            window.removeEventListener("resize", changeContainerHeight);
            window.visualViewport?.removeEventListener("resize", changeContainerHeight);
        };
    }, []);

    return (
        <div
            ref={appContainerRef}
            id={APP_CONTAINER_ID}
            className="zing-screen overflow-auto scroll-smooth font-outfit-zing"
        >
            {isEnableWarningFirstScreenTest &&
            warningFirstSreenVariant === undefined ? null : isWarningFirstScreen ? (
                <TechDifficultiesScreen />
            ) : (
                <Routes>
                    <Route path="/" element={<MainFlow />} />
                    <Route
                        path={AppRoutes.Calisthenics}
                        element={<MainFlow flowType={FlowTypes.Calisthenics} />}
                    />
                    <Route path="*" element={<Router />} />
                    <Route
                        path={AppRoutes.Loader}
                        element={<LoaderPromo onLoaded={() => null} />}
                    />
                </Routes>
            )}
            <div
                id={"preload-checkout-container-paypal"}
                className={"palta-payment-container hidden h-0"}
            ></div>
            <div
                id={"preload-checkout-container-card"}
                className={"palta-payment-container hidden h-0"}
            ></div>
            <div
                id={"preload-checkout-container-apple"}
                className={"palta-payment-container hidden h-0"}
            ></div>
        </div>
    );
};

export default memo(App);
