import { ReactNode, useEffect, useState } from "react";
import { init } from "@eppo/js-client-sdk";
import { logAnalyticsEvent, setAnalyticsUserProperties } from "@/constants/amplitude";
import { EPPO_API_KEY } from "@/constants/eppoApiKey";
import ExperimentsProvider from "./ExperimentsProvider";
import { WebEventTypes } from "@/constants/event";
import { useMatch } from "react-router-dom";
import { Routes as AppRoutes } from "@/constants/routes";
import { Sentry } from "@/utils/trackers";

interface IEppoRandomizationProvider {
    children: ReactNode;
    loadingComponent?: ReactNode;
}

export default function EppoRandomizationProvider({
    children,
    loadingComponent = null,
}: IEppoRandomizationProvider): ReactNode {
    const [isInitialized, setIsInitialized] = useState(false);
    const isLoader = useMatch({
        path: `/${AppRoutes.Loader}`,
        end: false,
    });

    const waitForInitialization = !isLoader;

    useEffect(() => {
        (async () => {
            try {
                const client = await init({
                    apiKey: EPPO_API_KEY, //if variant is null, assignmentLogger isn't called
                    assignmentLogger: {
                        logAssignment(assignment) {
                            assignment.experiment &&
                                setAnalyticsUserProperties({
                                    [assignment.experiment]: assignment.variation,
                                });
                            logAnalyticsEvent(WebEventTypes.AB_TEST, {
                                test_name: assignment.experiment,
                                group_name: assignment.variation,
                                feature_flag: assignment.featureFlag,
                            });
                        },
                    },
                    requestTimeoutMs: 30000,
                    pollAfterSuccessfulInitialization: true,
                    pollAfterFailedInitialization: true,
                });

                client.setIsGracefulFailureMode(false);
                setIsInitialized(true);
            } catch (e) {
                Sentry.captureException(e);
            }
        })();
    }, []);

    if (!waitForInitialization || isInitialized) {
        return <ExperimentsProvider>{children}</ExperimentsProvider>;
    }
    return loadingComponent;
}
