export const enum Colors {
    WARM_RED_300 = "#F1A49D",
    WARM_TANGERINE_100 = "#FDF0EC",
    WARM_TANGERINE_400 = "#F18360",
    WARM_GREEN_100 = "#EAFFF3",
    WARM_GREEN_400 = "#00DB5B",
    WARM_GREEN_500 = "#00B24A",
    WARM_GREEN_800 = "#002B12",
    WARM_YELLOW_100 = "#FFFAEB",
    WARM_YELLOW_500 = "#FAC938",
    WARM_YELLOW_800 = "#403000",
    WARM_TANGERINE_200 = "#FBDBD0",
    WARM_TANGERINE_300 = "#F6AF98",
    WARM_TANGERINE_500 = "#EC5929",
    WARM_RED_100 = "#FCEBEA",
    WARM_RED_500 = "#E24536",
    WARM_RED_700 = "#C30101",
    WARM_RED_800 = "#38110D",
    COOL_BLUE_100 = "#E7ECFC",
    COOL_BLUE_500 = "#1A4CE5",
    COOL_BLUE_600 = "#1339AB",
    COOL_BLUE_400 = "#5177EB",
    COOL_BLUE_800 = "#061338",
    NEUTRAL_COOL_GREY_200 = "#F3F4F7",
    NEUTRAL_COOL_GREY_300 = "#D7DAE5",
    NEUTRAL_COOL_GREY_400 = "#BEC4D5",
    NEUTRAL_COOL_GREY_600 = "#797F91",
    NEUTRAL_COOL_GREY_700 = "#4F535E",
    BLACK_200 = "#24262B",
    BLACK_300 = "#4F535E",
    LIME_200 = "#CBFF5B",
    WHITE_100 = "#E7ECFC",
    TRANSPARENT = "transparent",
    BLACK = "black",
    WHITE = "white",
}
