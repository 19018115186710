import { useCallback, useEffect, useState } from "react";

import { Consent } from "@/types/common";
import { IS_PROD_BUILD } from "@/constants/env";
import { AmplitudeTracker, PaltaDataPlatformTracker, TagManager, Sentry } from "@/utils/trackers";

const IS_DEV = !IS_PROD_BUILD;
enum TrackersTypes {
    marketing = "marketing",
    preferences = "preferences",
    statistics = "statistics",
}
const trackersByType = {
    [TrackersTypes.marketing]: [TagManager],
    [TrackersTypes.statistics]: [AmplitudeTracker, PaltaDataPlatformTracker],
    [TrackersTypes.preferences]: [Sentry],
};

const useConsent = () => {
    const [consent, setConsent] = useState<Consent | null>();

    const initTrackers = (type: TrackersTypes) => {
        trackersByType[type].forEach((tracker) => tracker.init());
    };

    const stopTrackers = (type: TrackersTypes) => {
        trackersByType[type].forEach((tracker) => tracker.stop());
    };

    const initAllTrackers = () => {
        initTrackers(TrackersTypes.marketing);
        initTrackers(TrackersTypes.statistics);
        initTrackers(TrackersTypes.preferences);
    };

    const saveConsent = useCallback(() => {
        setConsent(window.Cookiebot?.consent ? { ...window.Cookiebot?.consent } : null);
    }, []);

    useEffect(() => {
        const { method, stamp } = consent || {};
        const noConsentOnDomain =
            method === null && window.Cookiebot?.consentLifetime === null && stamp === "0";
        const initAll = noConsentOnDomain || IS_DEV;
        if (initAll) {
            initAllTrackers();
        } else {
            Object.values(TrackersTypes).forEach((trackersType) => {
                consent?.[trackersType] ? initTrackers(trackersType) : stopTrackers(trackersType);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consent]);

    useEffect(() => {
        saveConsent();
        window.addEventListener("CookiebotOnDecline", saveConsent);
        window.addEventListener("CookiebotOnAccept", saveConsent);

        return () => {
            window.removeEventListener("CookiebotOnDecline", saveConsent);
            window.removeEventListener("CookiebotOnAccept", saveConsent);
        };
    }, []);
};

export default useConsent;
