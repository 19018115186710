import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "../common/ErrorBoundary";
import GeolocationProvider from "@/utils/GeolocationProvider";
import GenderProvider from "@/utils/GenderProvider";
import ThemeProvider from "@/utils/ThemeProvider";
import EppoRandomizationProvider from "../utils/EppoRandomizationProvider";

export const BaseProviders = ({ children }: PropsWithChildren) => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <GenderProvider>
                    <ThemeProvider>
                        <GeolocationProvider>
                            <EppoRandomizationProvider>{children}</EppoRandomizationProvider>
                        </GeolocationProvider>
                    </ThemeProvider>
                </GenderProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};
