import React, { ReactNode, ErrorInfo } from "react";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

const IMPORTING_MODULE_ERROR = "Importing a module script failed";

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });

        console.error(error, errorInfo);

        if (error.message.includes(IMPORTING_MODULE_ERROR)) {
            window.location.reload();
        }
    }

    render(): ReactNode {
        return this.props.children;
    }
}

export default ErrorBoundary;
