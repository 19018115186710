import { IS_PROD_ENV } from "@/constants/env";
import TagManager from "react-gtm-module";
import { WebEventProps, WebEventTypes } from "@/constants/event";
import { Tracker } from "./common";
import { GAEvents } from "@/constants/gtag";
import { Genders } from "@/types/gender";

const PROD_GTM_ID = "GTM-P4MFXQN";
const DEV_GTM_ID = "GTM-5KDSXQNB";

type GAEventsType = {
    currency?: string;
    clientId?: string | null;
    email?: string;
    orderId?: string | null;
    price?: string | null;
    planId?: string | null;
    payment_system?: string | null;
    [WebEventProps.SCREEN]?: string;
    [WebEventProps.RESULT]?: unknown;
    event_id?: string;
};

class GoogleTagManager extends Tracker {
    private _eventsQueue: { action: GAEvents | WebEventTypes; value?: GAEventsType }[] = [];

    constructor() {
        super();
    }

    init() {
        if (!this._isInitialized) {
            const tagManagerArgs = {
                gtmId: IS_PROD_ENV ? PROD_GTM_ID : DEV_GTM_ID,
            };
            TagManager.initialize(tagManagerArgs);

            this._isInitialized = true;
            this.proceedEventsQueue();
        }

        if (this._isStoped) {
            this._isStoped = false;
        }
    }

    track(action: GAEvents | WebEventTypes, value?: GAEventsType) {
        if (this._isInitialized && !this._isStoped) {
            if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
                if (
                    [
                        GAEvents.Purchase,
                        GAEvents.ViewCart,
                        GAEvents.BeginCheckout,
                        GAEvents.SignUp,
                    ].includes(action as GAEvents)
                ) {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: action,
                            clientId: value?.clientId,
                            orderId: value?.orderId,
                            email: value?.email,
                            currency: value?.currency,
                            value: value?.price,
                            items:
                                action !== GAEvents.SignUp
                                    ? [
                                          {
                                              item_id: value?.planId,
                                              item_name: value?.planId,
                                              price: value?.price,
                                              quantity: 1,
                                              affiliation: value?.payment_system,
                                          },
                                      ]
                                    : undefined,
                            event_id: value?.event_id,
                        },
                    });
                    return;
                }
                if (action === GAEvents.Gender) {
                    const gender = value?.[WebEventProps.RESULT];
                    window.gtag("event", action, {
                        is_female: gender === Genders.Female,
                        is_male: gender === Genders.Male,
                    });
                    return;
                }

                window.gtag(
                    "event",
                    action,
                    value?.screen ? { question: value?.screen, answer: value?.result } : {}
                );
            }
        } else {
            this._eventsQueue.push({ action, value });
        }
    }

    private proceedEventsQueue() {
        this._eventsQueue.forEach(({ action, value }) => this.track(action, value));
    }
}

export default GoogleTagManager;
