import Logo from "@/common/Logo";
import { Colors } from "@/constants/colors";
import { EmailInput } from "@/common/EmailInput";
import { useEffect, useMemo, useState } from "react";
import { getIsValidEmail } from "@/utils/validateEmail";
import i18n from "@/localization/i18next";
import { useGender } from "@/utils/GenderProvider";
import { Genders } from "@/types/gender";
import StickyButton from "@/common/StickyButton";
import { logAnalyticsEvent, setAnalyticsUserProperties } from "@/constants/amplitude";
import { ReactComponent as UserSVG } from "@/assets/icons/circled/user.svg";
import { ReactComponent as LogoSVG } from "@/assets/icons/circled/logo-opacity-bg.svg";
import { WebEventProps, WebEventTypes } from "@/constants/event";

const SCREEN = "technical_difficulties";

const TechDifficultiesScreen = () => {
    const [email, setEmail] = useState("");
    const [isError, setIsError] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const { setGender } = useGender();

    const onEmailChange = (email: string) => {
        setEmail(email);
    };

    const isValidForm = useMemo(() => getIsValidEmail(email), [email]);

    useEffect(() => {
        logAnalyticsEvent(WebEventTypes.VIEW_EVENT, {
            [WebEventProps.SCREEN]: SCREEN,
        });
    }, []);

    useEffect(() => {
        setGender(Genders.Female);
    }, [setGender]);

    useEffect(() => {
        setIsError(false);
        if (!isEmailFocused && !isValidForm && email.length) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [isValidForm, isEmailFocused, email]);

    const submitEmail = async () => {
        setAnalyticsUserProperties({
            email_warning: true,
            email,
        });
        logAnalyticsEvent(WebEventTypes.WEB_BUTTON_CLICK, {
            [WebEventProps.BUTTON_NAME]: "notify",
            [WebEventProps.SCREEN]: SCREEN,
        });
        setIsEmailSubmitted(true);
    };

    return (
        <div className="zing-screen zing-max-w bg-white px-6 pt-8 text-zing-16-22 text-black">
            {isEmailSubmitted ? (
                <div className="mt-[20%] flex flex-col items-center gap-6">
                    <LogoSVG />
                    <div className="text-center text-zing-32-40 font-semibold">
                        <div>Thanks!</div>
                        <div>We'll notify you ASAP.</div>
                    </div>
                </div>
            ) : (
                <div className="grid gap-10">
                    <Logo fill={Colors.BLACK} />
                    <div className="grid gap-6">
                        <div className="text-zing-32-40 font-semibold">
                            We're Experiencing Technical Difficulties
                        </div>
                        <div className="grid gap-4">
                            <div>
                                We apologize for the inconvenience. Our iOS app is experiencing
                                technical issues preventing new user registrations. We understand
                                your eagerness to start your fitness journey with us, and we're
                                working tirelessly to resolve this as quickly as possible.
                            </div>
                            <div>
                                Please don't worry, we'll let you know as soon as we're back up and
                                running.
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-6">
                        <EmailInput
                            email={email}
                            setEmail={onEmailChange}
                            isEmailFocused={isEmailFocused}
                            setIsEmailFocused={setIsEmailFocused}
                        />
                        {isError && (
                            <div className="text-[12px] font-semibold leading-[18px] text-zing-warm-red-500">
                                {i18n.t("email_error")}
                            </div>
                        )}
                        <StickyButton
                            onClick={submitEmail}
                            isDisabled={!isValidForm}
                            btnClassName="md:max-w-none"
                        >
                            Notify Me
                        </StickyButton>
                    </div>
                    <div className="grid gap-2 rounded-2xl bg-zing-neutral-cool-grey-200 p-4">
                        <div className="flex items-center gap-2">
                            <UserSVG />
                            <div className="font-semibold">For existing users:</div>
                        </div>
                        <div className="text-zing-12-18">
                            The app continues to function normally. If you're an existing user
                            experiencing issues, please contact our{" "}
                            <a
                                href="https://zingcoach.zendesk.com/hc/en-us/requests/new"
                                className="underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                support team.
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TechDifficultiesScreen;
