import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";
import classNames from "classnames";
import customTwMerge from "../utils/customTwMerge";
import { getButtonTestIdByText } from "e2e-tests/testIds";

interface ButtonProps {
    children: ReactNode;
    isDisabled?: boolean;
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    style?: CSSProperties;
    disabledStyles?: string;
    id?: string;
    testId?: string;
    icon?: ReactNode | null;
    iconContainerClasses?: string;
    title?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            isDisabled,
            className = "",
            onClick,
            style,
            disabledStyles = "",
            id = "",
            testId,
            icon,
            iconContainerClasses,
            title,
        },
        ref
    ) => {
        const dataTestId =
            testId || (typeof children === "string" ? getButtonTestIdByText(children) : "");
        return (
            <button
                ref={ref}
                id={id}
                className={classNames(
                    customTwMerge(
                        `w-full rounded-2xl bg-zing-blue-200 py-4 text-zing-16-22 font-bold text-white female:bg-black md:max-w-[327px] md:text-zing-18-26`,
                        className
                    ),
                    {
                        [disabledStyles]: isDisabled,
                        "disabled:bg-zing-cool-blue-700 disabled:text-zing-cool-blue-400 female:disabled:bg-zing-neutral-cool-grey-200 female:disabled:text-zing-neutral-cool-grey-400":
                            !disabledStyles,
                    }
                )}
                disabled={isDisabled}
                onClick={onClick}
                style={style}
                data-zing-test-id={dataTestId}
                title={title}
            >
                {icon ? (
                    <div
                        className={classNames("button-inner", { "flex items-center gap-4": icon })}
                    >
                        {icon && <div className={iconContainerClasses}>{icon}</div>}
                        {children}
                    </div>
                ) : (
                    children
                )}
            </button>
        );
    }
);

export default React.memo(Button);
